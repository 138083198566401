import React, { useCallback } from 'react'
import classNames from 'classnames'
import { When } from 'react-if'
import { LinkButton } from '@/atoms/Button'
import CarouselNav from '@/atoms/CarouselNav'
import { Image } from '@/atoms/Image'
import { CaptionLG, HeadingMD, TitleLG, TitleMD } from '@/atoms/Text'
import { paths } from '@/constants'
import { GiftGuildButton } from '@/molecules/PayItForwardButton/GuildGiftButton'
import { useGuildUser } from '@/services/GuildUserService'
import { Collection } from '@/types/codegen-contentful'
import { useDimensions } from '@/utils/ScreenUtils'
import { useSafeAnalytics } from '@/utils/analytics'
import { useLoginUrl, useSignupUrl } from '@/utils/auth-utils'
import { useTranslate } from '@/utils/translate/translate-client'
import useCarousel from '@/utils/useCarousel'

const buttonClassNames = 'justify-center md:min-w-[121px] px-6 py-[14px] sm:w-auto max-h-12'
interface WatchForFreeProps {
  watchForFree?: Collection
  isLoggedIn: boolean
}

type WatchFreeType = {
  title?: string
  description?: string
  mobileImageUrl?: string
  desktopImageUrl?: string
  href?: string
}

export const WatchForFree: React.FC<WatchForFreeProps> = ({ watchForFree, isLoggedIn }) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [freeScrollerRef, { canScrollBack, canScrollForward, scrollBack, scrollForward }] =
    useCarousel<HTMLDivElement>()
  const { width: containerWidth } = useDimensions(containerRef)
  const { t } = useTranslate('home')
  const { isGuildMember } = useGuildUser()

  if (!watchForFree) return null

  const leftPadding = containerWidth && containerWidth > 1560 && (containerWidth - 1550) / 2 + 112

  return (
    <section
      ref={containerRef}
      className="relative flex h-fit w-full flex-col items-start justify-center bg-core-gray-950 text-white"
    >
      <div className="w-full flex-col items-center justify-center">
        <div className="relative flex w-full flex-col px-4 pt-24 md:px-8 lg:px-28 lg:pt-28 2xl:mx-auto 2xl:w-[1550px]">
          <HeadingMD className="md:photon-heading-lg lg:photon-display max-w-[344px] lg:max-w-[579px]" weight="bold">
            {t('watchGreatContentFree', 'Watch great content for free!')}
          </HeadingMD>
          <CaptionLG
            className="md:photon-paragraph-lg md:max-w-auto mt-3 max-w-[370px]"
            weight="medium"
            color="core-gray-500"
          >
            {t('madePossibleByGuild', 'Made possible by thousands of Guild members.')}
          </CaptionLG>
          <div className="hidden md:flex">
            <LoginButtons isLoggedIn={isLoggedIn} buttonClassNames={buttonClassNames} isGuildMember={isGuildMember} />
          </div>
          <TitleLG className="-mb-4 mt-8 capitalize md:mt-[88px]" weight="semibold">
            {t('newAndTrending', 'New and Trending')}
          </TitleLG>
        </div>
        <div className="relative w-full">
          <div
            className="relative flex w-full snap-x snap-mandatory flex-row gap-8 overflow-scroll px-4 scrollbar-none sm:flex-row md:mb-[118px] md:px-8 lg:px-28"
            style={{ paddingLeft: leftPadding ?? 0 }}
            ref={freeScrollerRef}
          >
            {watchForFree?.customData.map((data: WatchFreeType) => {
              return <AngelCard key={data.title} {...data} />
            })}
          </div>
          <CarouselNav
            onClick={scrollBack}
            active={canScrollBack}
            orientation="horizontal"
            direction="back"
            className={classNames('ml-4', { 'opacity-75 hover:opacity-100': canScrollBack })}
            theme="dark"
          />
          <CarouselNav
            onClick={scrollForward}
            active={canScrollForward}
            orientation="horizontal"
            direction="forward"
            className={classNames('mr-4', { 'opacity-75 hover:opacity-100': canScrollForward })}
            theme="dark"
          />
        </div>
        <div className="relative flex w-full flex-col pb-24 md:hidden">
          <LoginButtons isLoggedIn={isLoggedIn} buttonClassNames={buttonClassNames} isGuildMember={isGuildMember} />
        </div>
      </div>
    </section>
  )
}

const AngelCard: React.FC<WatchFreeType> = ({ title, description, mobileImageUrl, desktopImageUrl, href }) => {
  const { track } = useSafeAnalytics()

  return (
    <a
      href={href}
      onClick={() => track('Watch For Free Card Clicked')}
      className="flex snap-center flex-col justify-between py-8 text-white"
    >
      <div className="overflow-hidden rounded-2xl shadow-dark-3">
        <Image
          className="flex min-h-[375px] min-w-[250px] md:hidden"
          width={250}
          height={375}
          src={mobileImageUrl ?? desktopImageUrl ?? ''}
          alt={title}
        />
        <Image
          className="hidden min-h-[238px] min-w-[450px] md:flex lg:hidden"
          width={426}
          height={238}
          src={desktopImageUrl ?? mobileImageUrl ?? ''}
          alt={title}
        />
        <Image
          className="hidden min-h-[360px] min-w-[644px] lg:flex"
          width={644}
          height={360}
          src={desktopImageUrl ?? mobileImageUrl ?? ''}
          alt={title}
        />
      </div>
      <div className="mt-[17px] flex flex-col items-start gap-2 text-left md:flex-row md:items-center md:gap-3">
        <TitleMD className="md:photon-title-lg" weight="bold">
          {title}
        </TitleMD>
        <CaptionLG className="md:photon-paragraph-lg" color="core-gray-500" weight="medium">
          {description}
        </CaptionLG>
      </div>
    </a>
  )
}

const LoginButtons = ({
  isLoggedIn,
  buttonClassNames,
  isGuildMember,
}: {
  isLoggedIn: boolean
  buttonClassNames: string
  isGuildMember: boolean
}) => {
  const { t } = useTranslate('home')
  const { track } = useSafeAnalytics()
  const { signupUrl, trackSignupStarted } = useSignupUrl(paths.signup.index)
  const { loginUrl, trackLoginStarted } = useLoginUrl(paths.signup.index)

  const handleSignupClicked = useCallback(() => {
    trackSignupStarted('Home Watch For Free Clicked')
  }, [trackSignupStarted])

  const handleLoginClicked = useCallback(() => {
    trackLoginStarted('Home Watch For Free Clicked')
  }, [trackLoginStarted])

  return (
    <div className="mx-4 mb-2 mt-6 flex flex-row items-center justify-center gap-4 md:mx-0 md:flex-row md:justify-start">
      <When condition={isLoggedIn && isGuildMember}>
        <GiftGuildButton />
        <LinkButton
          href={paths.watch.index}
          variant="white"
          outline
          className={classNames(
            buttonClassNames,
            'border-0 !bg-core-gray-800 hover:!bg-core-gray-800 text-white grow md:grow-0',
          )}
          onClick={() => track('Home Watch Clicked', { context: 'home-watch-for-free' })}
        >
          <div className="relative">{t('watchNow', 'Watch Now')}</div>
        </LinkButton>
      </When>
      <When condition={isLoggedIn && !isGuildMember}>
        <LinkButton
          href={paths.guild.join}
          variant="white"
          className={buttonClassNames}
          onClick={() => track('Clicked Learn More', { context: 'home-watch-for-free' })}
        >
          <div className="relative flex w-full flex-row items-center justify-center gap-2">
            {t('learnMore', 'Learn More')}
          </div>
        </LinkButton>
        <LinkButton
          href={paths.watch.index}
          variant="white"
          outline
          className={classNames(
            buttonClassNames,
            'border-0 !bg-core-gray-800 hover:!bg-core-gray-800 text-white grow md:grow-0',
          )}
          onClick={() => track('Home Watch Clicked', { context: 'home-watch-for-free' })}
        >
          <div className="relative">{t('watchNow', 'Watch Now')}</div>
        </LinkButton>
      </When>
      <When condition={!isLoggedIn}>
        <LinkButton href={signupUrl} variant="white" className={buttonClassNames} onClick={handleSignupClicked}>
          <div className="relative flex w-full flex-row items-center justify-center gap-2">
            {t('signUpForFree', 'Sign up for Free')}
          </div>
        </LinkButton>
        <LinkButton
          href={loginUrl}
          variant="core-gray-800"
          outline
          className={classNames(buttonClassNames, 'border-0 !bg-core-gray-800 hover:!bg-core-gray-800 text-white')}
          onClick={handleLoginClicked}
        >
          <div className="relative flex w-full flex-row items-center justify-center gap-2">{t('logIn', 'Log In')}</div>
        </LinkButton>
      </When>
    </div>
  )
}
