import React, { useCallback, useEffect } from 'react'
import { ApolloClient, useLazyQuery } from '@apollo/client'
import { FloatingCallToActionImage } from '@/molecules/FloatingCallToAction/FloatingCallToActionImage'
import { GET_FLOATING_CALL_TO_ACTION_QUERY } from '@/services/CmsService'
import { GetFloatingCallToActionQuery } from '@/types/codegen-contentful'
import { GuildRoles } from '@/types/codegen-federation'
import { useSafeAnalytics } from '@/utils/analytics'
import { FloatingCallToActionDisplay } from './FloatingCallToActionDisplay'

interface FloatingCallToActionProps {
  client: ApolloClient<object>
  locale: string
  preview: boolean
  region: string
  isGuildMember: boolean
  guildRoles?: GuildRoles
}

export const FloatingCallToAction: React.FC<FloatingCallToActionProps> = ({
  client,
  locale,
  preview,
  region,
  isGuildMember,
  guildRoles,
}) => {
  const { track } = useSafeAnalytics()
  const [getFloatingCta, { data, loading }] = useLazyQuery<GetFloatingCallToActionQuery>(
    GET_FLOATING_CALL_TO_ACTION_QUERY,
    {
      client,
    },
  )

  const handleClick = useCallback(() => {
    track('On Tap', {
      element_name: 'cta_tickets_floating',
      value: data?.callToActionCollection?.items?.[0],
    })
  }, [data, track])

  useEffect(() => {
    if (locale && region) {
      getFloatingCta({
        variables: {
          locale,
          preview,
          region,
        },
      })
    }
  }, [getFloatingCta, locale, preview, region])

  const firstCTA = data?.callToActionCollection?.items?.[0]
  if (loading || !firstCTA) return null

  return (
    <div>
      <div className="hidden sm:block">
        <FloatingCallToActionImage
          floatingCta={firstCTA}
          onClick={handleClick}
          isGuildMember={isGuildMember}
          guildRoles={guildRoles}
        />
      </div>
      <div className="block w-full sm:hidden">
        <FloatingCallToActionDisplay
          floatingCta={firstCTA}
          onClick={handleClick}
          isGuildMember={isGuildMember}
          guildRoles={guildRoles}
        />
      </div>
    </div>
  )
}
