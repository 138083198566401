import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { slugs } from '@/constants'
import { useLanding } from '@/contexts/LandingContext'
import { useRegionContext } from '@/contexts/RegionContext'
import { FeaturedProjectSection } from '@/organisms/FeaturedProjectSection'
import { InvestmentBanner } from '@/organisms/InvestmentBanner'
import { Page } from '@/page'
import { getContentfulClient } from '@/services/ApolloClient'
import { getFeaturedProjectsQuery } from '@/services/CmsService'
import { contentfulFaqLocations, useProjectFaqs } from '@/services/FaqService'
import { useGuildUser } from '@/services/GuildUserService'
import { PageDataContext, PageModel } from '@/services/RenderService'
import { GetFeaturedProjectsQuery, InvestmentBanner as InvestmentBannerContent } from '@/types/codegen-contentful'
import { useLocale } from '@/utils/LocaleUtil'
import { AppPromoSection } from '@/views/LandingView/AppPromoSection'
import { CreatePromoSection } from '@/views/LandingView/CreatePromoSection'
import { FeaturedShowSection } from '@/views/LandingView/FeaturedShowSection'
import { AngelCommunity } from './AngelCommunitySection'
import { FaqSection } from './FaqSection'
import { JoinTheMovement } from './JoinTheMovementSection'
import { MainHeroSection } from './MainHeroSection'
import { WatchForFree } from './WatchForFreeSection'

interface LandingView {
  investmentBanner?: InvestmentBannerContent
  isLoggedIn: boolean
  preview: boolean
  pageDataContext: Maybe<PageDataContext>
  page: Maybe<PageModel>
}

export const LandingView: React.FC<LandingView> = ({
  investmentBanner,
  isLoggedIn,
  preview,
  page,
  pageDataContext,
}) => {
  const { locale } = useLocale()
  const { watchForFree } = useLanding()
  const { isGuildMember } = useGuildUser()
  const { guildRoles } = useGuildUser()
  const { loading: loadingRegion, region } = useRegionContext()
  const client = getContentfulClient({ locale, preview, region })
  const isUSorCanada = region === 'US' || region === 'CA'
  const { faqs, loadingProjectFaqs } = useProjectFaqs({
    region,
    locale,
    preview,
    projectSlug: slugs.angelStudios,
    location: contentfulFaqLocations.home,
  })
  const [getFeaturedProjects, { data: featuredProjectsData, loading: loadingFeaturedProjects }] =
    useLazyQuery<GetFeaturedProjectsQuery>(getFeaturedProjectsQuery, {
      client,
    })

  useEffect(() => {
    if (!loadingRegion && region) {
      getFeaturedProjects({ variables: { locale, preview, region } })
    }
  }, [getFeaturedProjects, loadingRegion, locale, preview, region])

  const firstFeaturedProjectId = featuredProjectsData?.featuredProjectCollection?.items?.[0]?.sys?.id

  return (
    <div className="mx-auto flex flex-col bg-core-gray-950 text-white">
      <MainHeroSection
        client={client}
        isLoggedIn={isLoggedIn}
        loading={false}
        preview={preview}
        region={region}
        isGuildMember={isGuildMember}
        guildRoles={guildRoles}
      />
      {investmentBanner && <InvestmentBanner {...investmentBanner} className="my-6 md:my-8 lg:my-12" />}
      {!!page && !!pageDataContext && (
        <section className="flex flex-col gap-y-12 md:pt-4">
          <Page page={page} pageDataContext={{ ...pageDataContext, contentItemConfig: { padding: true } }} />
        </section>
      )}
      <When condition={Boolean(watchForFree)}>
        <WatchForFree watchForFree={watchForFree} isLoggedIn={isLoggedIn} />
      </When>
      <LazyHydrate whenVisible>
        <When condition={!isGuildMember}>
          <JoinTheMovement />
        </When>
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <AngelCommunity />
      </LazyHydrate>
      <div className="bg-core-gray-950 pt-4">
        <AppPromoSection />
      </div>

      <When condition={isUSorCanada && !loadingFeaturedProjects && firstFeaturedProjectId}>
        <FeaturedProjectSection featuredProjectId={firstFeaturedProjectId as string} />
      </When>

      <div className="bg-core-gray-950 pb-12">
        <FeaturedShowSection />
      </div>

      <CreatePromoSection />

      {!loadingProjectFaqs && <FaqSection faqs={faqs} />}
    </div>
  )
}
