import React from 'react'
import LazyHydrate from 'react-lazy-hydration'
import { LinkButton } from '@/atoms/Button'
import { Image } from '@/atoms/Image'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsH2, HeadingXS, ParagraphSM } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'

export const CreatePromoSection: React.FC = () => {
  const { t } = useTranslate('home')

  return (
    <section role="complementary" className="relative mt-20 w-full py-20">
      <LazyHydrate whenVisible>
        <ResponsiveBackgroundImage
          src={getCloudinaryImageUrl({
            path: '/v1721683035/angel-studios/landing/Create%20Promo/0188807e335a517eaec4c73dee15d661.webp',
          })}
        />
      </LazyHydrate>
      <div className="relative w-full md:py-20">
        <PaddedContainer>
          <div className="relative flex flex-col items-center gap-6 lg:flex-row lg:gap-10 xl:gap-14">
            <div className="py-auto w-full lg:w-1/2">
              <div className="flex w-full flex-col items-center px-6 text-center lg:max-w-[577px] lg:items-start lg:text-left 2xl:max-w-[675px]">
                <header>
                  <HeadingXS
                    aria-label="launch-your-story"
                    weight="bold"
                    as={AsH2}
                    className="md:photon-heading-md lg:photon-heading-xl xl:photon-display mb-4"
                  >
                    {t('launchYourStory', 'Launch your story.')}
                  </HeadingXS>
                </header>
                <ParagraphSM
                  aria-label="community-involvement"
                  weight="medium"
                  color="core-gray-400"
                  className="lg:max-w-auto md:max-w-[450px]"
                >
                  {t(
                    'launchYourStoryDescriptionv4',
                    'Angel Studios empowers filmmakers with creative control, Guild feedback, and access to a passionate global audience that supports light amplifying stories. Join the movement to shape the future of entertainment—submit your story today!',
                  )}
                </ParagraphSM>
                <div className="mt-12 flex flex-row items-center justify-center gap-2">
                  <LinkButton href={paths.filmmakers.index} variant="white">
                    {t('getStarted', 'Get Started')}
                  </LinkButton>
                  <LinkButton
                    href={paths.filmmakers.index}
                    variant="white"
                    outline
                    className="border-0 hover:!bg-transparent"
                  >
                    {t('learnMore', 'Learn More')}
                  </LinkButton>
                </div>
              </div>
            </div>
            <div className="mb-4 flex h-full w-full items-center lg:mb-0 lg:ml-4 lg:w-1/2">
              <Image
                aria-label={t('launchYourStoryCollage', 'Angel Studios fans and creators collage')}
                src={getCloudinaryImageUrl({
                  path: '/v1721682605/angel-studios/landing/Create%20Promo/launch-image.webp',
                  width: 813,
                  height: 837,
                })}
                width={813}
                height={837}
                alt={t('launchYourStoryCollage', 'Angel Studios fans and creators collage')}
              />
            </div>
          </div>
        </PaddedContainer>
      </div>
    </section>
  )
}
